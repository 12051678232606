import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { key: 4 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_api_grid = _resolveComponent("api-grid")!
  const _component_content_card = _resolveComponent("content-card")!

  return (_openBlock(), _createBlock(_component_content_card, null, {
    body: _withCtx(() => [
      _createVNode(_component_api_grid, {
        id: "sent-email-grid",
        url: "/api/admin/SentEmailGrid",
        columns: _ctx.emailsSentColumns,
        sort: [{ field: 'insertedOn', dir: 'desc' }],
        filterable: true
      }, {
        default: _withCtx(({ props }) => [
          (props.field == 'to')
            ? (_openBlock(), _createElementBlock("td", _hoisted_1, _toDisplayString(props.dataItem.to), 1))
            : _createCommentVNode("", true),
          (props.field == 'from')
            ? (_openBlock(), _createElementBlock("td", _hoisted_2, _toDisplayString(props.dataItem.from), 1))
            : _createCommentVNode("", true),
          (props.field == 'subject')
            ? (_openBlock(), _createElementBlock("td", _hoisted_3, _toDisplayString(props.dataItem.subject), 1))
            : _createCommentVNode("", true),
          (props.field == 'body')
            ? (_openBlock(), _createElementBlock("td", {
                key: 3,
                innerHTML: props.dataItem.body
              }, null, 8, _hoisted_4))
            : _createCommentVNode("", true),
          (props.field == 'insertedOn')
            ? (_openBlock(), _createElementBlock("td", _hoisted_5, _toDisplayString(_ctx.formatDateTime(props.dataItem.insertedOn)), 1))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["columns"])
    ]),
    _: 1
  }))
}